<template>
    <div class="success-page">
        <img src="@public/images/icons/success.png">
        <h2>{{ $t('We are already working on it!') }}</h2>
        <h3>{{ $t('Thank you for using Pobo Page Builder plugin') }} ❤️</h3>
        <div class="success-page__recapitulation">
            <div class="success-page__recapitulation-info">
                <ul v-if="formData">
                    <li>{{ $t('Request:') }} {{ formData.problem }}</li>
                    <li>{{ $t('Contact email:') }} {{ formData.email }}</li>
                    <li v-if="formData.message">{{ $t('Message:') }} {{ formData.message }}</li>
                    <li v-if="formData.urls">{{ $t('Product URLs:') }}
                        <span v-for="(item, index) in formData.urls" :key="index">
                            {{ index + 1 }}. {{ item }} &nbsp;
                        </span>
                    </li>
                    <li v-if="formData.credits">{{ $t('Number of credits for the order:') }} {{ formData.credits }}</li>
                </ul>
            </div>

            <div class="success-page__reload">
                <button class="success-page__btn"
                        @click.prevent="reload"
                >
                    <img src="@public/images/icons/reload.svg" alt="reload">
                    {{ $t('Create a new request') }}
                </button>
            </div>
        </div>
    </div>
</template>


<script>
import Cookies from "js-cookie";

export default {
    name: "SuccessPage",

    data() {
        return {
            formData: {}
        }
    },

    mounted() {
        this.formData = JSON.parse(Cookies.get('poboSupportForm') || '{}');
    },

    methods: {
        reload() {
            const {$router, $route} = this;
            Cookies.remove('poboSupportForm');
            $router.push({name: 'SupportForm', query: $route.query});
        },
    }
}
</script>
