<template>
    <div class="pobo-support-form__information">
        <h2> {{ $t(heading) }} </h2>
        <p> {{ $t(text) }} </p>
    </div>
</template>

<script>
export default {
    name: "InformationBox",

    props: {
        heading: {
            required: true,
            type: String
        },
        text: {
            required: true,
            type: String
        }
    }
}
</script>
