<template>
    <div class="pobo-support-url">
        <button
            @click="addUrlField"
            class="pobo-support-url__add-btn"
        >
            <img src="@public/images/icons/add.png" alt="add">
            {{ $t('Add another URL') }}
        </button>
        <div v-for="(url, index) in urls" :key="index" class="pobo-support-url__input-wrapper">
            <input
                class="pobo-support-url__input"
                v-model="urls[index]"
                @blur="emitUrls"
                @input="validateUrl"
                :placeholder="$t('Enter URL')"
                type="url"
            >
            <button
                v-if="urls.length > 1"
                @click="removeUrlField(index)"
                class="pobo-support-url__remove-btn"
            >
                <img src="@public/images/icons/delete.png" alt="delete">
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UrlTemplate',

    data() {
        return {
            urls: [''],
            oldCheck: false,
        }
    },

    methods: {
        addUrlField() {
            this.urls.push('');
        },

        validateUrl() {
            let lastCheck = false;

            lastCheck = this.urls[0].length > 6;

            if (this.oldCheck !== lastCheck) {
                this.oldCheck = lastCheck;
                this.$emit('dataConfirm', lastCheck);
            }

        },

        removeUrlField(index) {
            this.urls.splice(index, 1);
            this.emitUrls();
        },

        emitUrls() {
            this.$emit('updateUrls', this.urls);
        }
    },
}
</script>
<style lang="scss" scoped>
.pobo-support-url {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;

    &__remove-btn {
        background: unset;
        border: none;
    }

    &__add-btn {
        background: transparent;
        border: 1px solid #4d4d4d;
        border-radius: 5px;

        &:hover {
            background: rgba(234, 234, 234, 0.16);
        }
    }

    &__add-btn, &__remove-btn {
        display: flex;
        align-items: center;
        gap: 7px;
        margin: 10px 0;
        padding: 5px 10px;
        font-size: 16px;
        cursor: pointer;

        img {
            max-width: 25px;
        }
    }

    &__input-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
    }


    &__input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        margin-right: 5px;
        border: 1px solid #ced4da;
        border-radius: .25rem;

        &:focus {
            outline: none;
            border-color: #649b0d;
        }
    }
}
</style>
