<template>
    <div class="pobo-support-email">
        <input
            @blur="emitEmail"
            @input="validateEmail"
            v-model="emailValue"
            class="pobo-support-email__input"
            placeholder="E-mail"
            type="email"
            id="email" name="email">
    </div>
</template>

<script>
export default {
    name: 'EmailTemplate',

    data() {
        return {
            active: null,
            emailValue: '',
            isValidEmail: false,
            oldCheck: false,
        }
    },

    methods: {
        emitEmail() {
            this.$emit('updateEmail', this.emailValue);
        },
        validateEmail() {
            let lastCheck = false;

            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailValue)) {
                lastCheck = true;
            } else {
                lastCheck = false;
            }

            if (this.oldCheck !== lastCheck) {
                this.oldCheck = lastCheck;
                this.$emit('dataConfirm', lastCheck);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.pobo-support-email {
    display: flex;
    margin-top: 35px;
    width: 100%;

    &__input {
        width: 100%;
        padding: 11px 9px;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        &:focus {
            outline: none;
            border-color: #649b0d;
        }
    }
}
</style>
