<template>
    <div class="pobo-support-message">
        <textarea class="pobo-support-message__textarea" :placeholder="$t('Message:')" id="message" name="message"
                  @input="validateMessage" @blur="emitMessage" v-model="messageValue"></textarea>
        <div class="pobo-support-message__files" v-if="showFiles">
            <h2> {{ $t('You can attach photos') }}</h2>
            <button @click="openFileDialog" class="pobo-support-message__upload-button">
                <img src="@public/images/icons/upload.png" alt="upload">
                {{ $t('Upload files') }}
            </button>
            <span> {{ $t('Upload files one at a time - maximum size 10 MB') }} </span>
            <input accept="image/png, image/jpeg, image/jpg" type="file" name="files[]" ref="fileInput"
                   @change="handleFiles" multiple style="display: none;">
            <div v-if="loading" class="loading">{{ $t('Uploading...') }}</div>

            <ul class="uploaded-files">
                <li v-for="file in uploadedFiles" :key="file.path">
                    {{ file.name }}
                    <button @click="deleteFile(file.id)">❌</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'MessageTemplate',
    props: {
        showFiles: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            messageValue: '',
            uploadedFiles: [],
            loading: false
        }
    },

    mounted() {
        window.addEventListener('beforeunload', this.cleanupFiles);
    },

    beforeDestroy() {
        window.removeEventListener('beforeunload', this.cleanupFiles);
    },

    methods: {
        openFileDialog() {
            this.$refs.fileInput.click();
        },
        handleFiles(event) {
            if (event.target.files.length > 0) {
                this.loading = true;
                const formData = new FormData();
                Array.from(event.target.files).forEach(file => {
                    formData.append('files[]', file);
                });
                axios.post('/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.data[0]) {
                            this.uploadedFiles.push(response.data[0]);
                        }
                        this.loading = false;
                        this.$emit('filesUploaded', this.uploadedFiles);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        this.loading = false;
                    });
            }
        },
        deleteFile(fileId) {
            axios.delete(`/api/files/${fileId}`)
                .then(() => {
                    this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== fileId);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },

        cleanupFiles(event) {
            const message = "Máte neuložené soubory. Opravdu chcete opustit stránku?";
            if (this.uploadedFiles.length > 0) {
                event.returnValue = message;
                const fileIds = this.uploadedFiles.map(file => file.id);
                axios.post('/api/files', {fileIds})
                    .then(response => {
                        this.uploadedFiles = [];
                    })
                    .catch(error => console.error('Cleanup failed', error));
            }
            return message;
        },
        emitMessage() {
            this.$emit('updateMessage', this.messageValue);
        },
        validateMessage() {
            this.$emit('dataConfirm', this.messageValue.length > 10);
        },
    },
}
</script>

<style lang="scss" scoped>
.pobo-support-message {
    display: flex;
    margin-top: 35px;
    width: 100%;
    flex-direction: column;


    &__upload-button {
        padding: 10px 20px;
        background-color: #649b0d;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 18px;

        img {
            width: 25px;
            filter: invert(1);
        }

        &:hover {
            background-color: darken(#649b0d, 10%);
        }
    }

    &__files {
        margin: 30px 0;
        position: relative;

        span {
            font-size: 13px;
            color: gray;
            margin-top: 8px;
            display: flex;
        }

        ul {
            margin-top: 15px;
            margin-left: 15px;
            color: #649b0d;

            button {
                background: transparent;
                border: none;
                cursor: pointer;
            }
        }

        h2 {
            color: #02044a;
            font-weight: 700;
            margin-bottom: 15px
        }

        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            color: #649b0d;
        }
    }

    &__textarea {
        width: 100%;
        height: 200px;
        padding: 11px 9px;
        font-size: 17px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        resize: none;

        &:focus {
            outline: none;
            border-color: #649b0d;
        }
    }
}
</style>
