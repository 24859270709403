<template>
    <div class="pobo-support-credit">
        <input
            class="pobo-support-credit__input"
            type="number"
            min="500"
            @input="validateCredit"
            :placeholder="$t('Enter Credit')"
            v-model.number="creditAmount"
            @blur="emitCredit"
        >
    </div>
</template>
<script>
export default {
    name: 'CreditOrder',

    data() {
        return {
            creditAmount: 0,
        }
    },

    methods: {
        emitCredit() {
            if (this.creditAmount < 500) {
                this.creditAmount = 500;
            } else {
                this.$emit('updateCredit', this.creditAmount);
            }
        },

        validateCredit() {
            if (this.creditAmount < 500) {
                this.$emit('dataConfirm', false);
            } else {
                this.$emit('dataConfirm', true);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.pobo-support-credit {
    margin-top: 35px;

    &__input {
        width: 100%;
        padding: 15px 12px;
        font-size: 20px;
        font-weight: bold;
        color: #495057;
        background-color: #fff;
        border: 2px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out;

        &:focus {
            outline: none;
            border-color: #649b0d;
        }
    }
}
</style>
