<template>
    <div class="pobo-support-problem">
        <a @click.prevent="selectProblem(item)" href="#" v-for="item in problems"
           :class="['pobo-support-problem__item', {'pobo-support-problem__item--active': active === item.id}]">
            <img :src="'/images/icons/' + item.ico + '.png'" alt="pobo-support-ico">
            {{ $t(item.text) }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'ChooseProblem',

    data() {
        return {
            active: null,
            problems: [
                {
                    id: 0,
                    ico: 'upgrade',
                    text: 'Tariff inquiry',
                },
                {
                    id: 1,
                    ico: 'browser',
                    text: 'Technical issue with add-on',
                },
                {
                    id: 2,
                    ico: 'search',
                    text: 'Problem with e-shop labels',
                },
                {
                    id: 3,
                    ico: 'stationery',
                    text: 'I need a custom adjustment',
                },
                {
                    id: 4,
                    ico: 'terminal',
                    text: 'I want to order credits',
                },
                {
                    id: 5,
                    ico: 'growth',
                    text: 'Other',
                },
            ],
        }
    },

    methods: {
        selectProblem(item) {
            this.active = item.id;
            this.$emit('selected', item);
            this.$emit('dataConfirm', true);
        }
    },
}
</script>
