import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import {createI18n} from 'vue-i18n'
import csLang from "@/locales/cs.json";
import huLang from "@/locales/hu.json";
import skLang from "@/locales/sk.json";
import enLang from "@/locales/en.json";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/vue";

const messages = {
    cs: csLang,
    hu: huLang,
    en: enLang,
    sk: skLang,
    silentTranslationWarn: true,
};

const app = createApp(App);

const i18n = createI18n({
    locale: 'cs',
    fallbackLocale: 'en',
    messages,
    missing: (locale, key, instance, values) => {
        return key;
    },
    silentTranslationWarn: true
})
router.beforeEach((to, from, next) => {
    const lang = to.params.lang || 'cs';
    i18n.global.locale = lang;

    const hasCookie = checkMyObjectCookie();

    if (to.name === 'SuccessPage') {
        if (!hasCookie) {
            next({name: 'SupportForm', params: {lang: lang}});
        } else {
            next();
        }
    } else if (hasCookie) {
        next({name: 'SuccessPage', params: {lang: lang}});
    } else {
        next()
    }
});


function checkMyObjectCookie() {
    return Cookies.get('poboSupportForm') !== undefined;
}

Sentry.init({
    app,
    dsn: "https://12020ffffbfdc27c9679551fd0b3340e@o4505739655512064.ingest.us.sentry.io/4507255145889792",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracingOrigins: ["localhost", "pobo-support.com", /^\https:\/\/pobo-support\.com\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(i18n);
app.use(router);
app.mount('#app');
