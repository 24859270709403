<template>
    <div class="error-page">
        <img src="@public/images/icons/warning.png">
        <h3> {{ $t('Unfortunately, submitting the request failed.') }}</h3>
        <h2>
            {{ $t('Please contact us via email') }}
            <a href="mailto:podpora@pobo.cz">
                podpora@pobo.cz
            </a>
        </h2>
        <div class="error-page__contact">
            <a class="error-page__btn"
               href="mailto:podpora@pobo.cz"
            >
                <img src="@public/images/icons/email.png" alt="reload">
                {{ $t('Please write to us by email') }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorPage",
}
</script>
