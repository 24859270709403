import {createRouter, createWebHistory} from 'vue-router';
import SupportForm from '@/js/pages/SupportForm.vue';
import SuccessPage from "@/js/pages/SuccessPage.vue";
import ErrorPage from "@/js/pages/ErrorPage.vue";
import Cookies from "js-cookie";
import {createI18n} from "vue-i18n";

const routes = [
    {path: '/:lang/', component: SupportForm, name: 'SupportForm'},
    {path: '/:lang/success', component: SuccessPage, name: 'SuccessPage'},
    {path: '/:lang/error', component: ErrorPage, name: 'ErrorPage'},
];


const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
